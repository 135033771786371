import StoreInfo from '@common/models/StoreInfo';
import { RouteMap } from '@common/RouteMap';
import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import { getBuyerJWTToken } from '@common/utils/token';
import FacebookFooter from '@images/Facebook.svg';
import InstagramFooter from '@images/Instagram.svg';
import LinkedinFooter from '@images/Linkedin.svg';
import Logo from '@images/Logo.svg';
import PinterestFooter from '@images/Pinterest.svg';
import TwitterFooter from '@images/Twitter.svg';
import YoutubeFooter from '@images/Youtube.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

interface FooterProps {
  storeInfo: StoreInfo;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full bg-disabled" color-customisation="footer-bg" id="page-footer">
      <div className="container mx-auto py-60 justify-between">
        <div className="mb-44 md:mb-30">
          <Link to="/" id="footer-logo">
            {pageHeaderLayout?.logo ? (
              <Image
                noDefaultDimensions
                src={pageHeaderLayout?.logo}
                alt={storeInfo?.storename}
                className="max-h-34 md:max-h-52 md:max-w-168 max-w-110"
              />
            ) : (
              <div className="font-medium text-20 text-theme">{storeInfo?.storename}</div>
            )}
          </Link>
        </div>
        {/* <br /> */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
          <div className="mb-40">
            <div
              className="text-theme text-14 mb-22 md:mb-16 tracking-14 md:tracking-16 leading-16 md:leading-19 uppercase font-bold"
              font-customisation="section-heading"
              color-customisation="footer-heading"
            >
              MENU
            </div>
            {arePoliciesPresent && !policyTos?.privacy && (
              <Link
                to="/policy/privacy"
                className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-privacy"
              >
                Privacy
              </Link>
            )}
            {arePoliciesPresent && !policyTos?.termsAndConditions && (
              <Link
                to="/policy/tnc"
                className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-terms-conditions"
              >
                Terms &amp; Conditions
              </Link>
            )}
            {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
              <Link
                to="/policy/shipping"
                className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-shipping-delivery"
              >
                Shipping &amp; Delivery
              </Link>
            )}
            {arePoliciesPresent && !policyTos?.cancelAndRefund && (
              <Link
                to="/policy/cancellation"
                className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-cancellation-refund"
              >
                Cancellation &amp; Refund
              </Link>
            )}
          </div>
          {features?.features?.customPages && (
            <div className="mb-40">
              <div
                className="text-theme text-14 mb-22 md:mb-16 tracking-14 md:tracking-16 leading-16 md:leading-19 uppercase font-bold"
                font-customisation="section-heading"
                color-customisation="footer-heading"
              >
                CUSTOMER SERVICE
              </div>
              {getActiveNavbar('About Us', filterBar) && (
                <Link
                  to={RouteMap.ABOUT}
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-about-us"
                >
                  About Us
                </Link>
              )}
              {features?.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                <Link
                  to={RouteMap.CONTACT}
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-contact-us"
                >
                  Contact Us
                </Link>
              ) : null}
              {getActiveNavbar('FAQs', filterBar) && (
                <Link
                  to={RouteMap.FAQ}
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  id="footer-faqs"
                  color-customisation="footer-links"
                >
                  FAQs
                </Link>
              )}
            </div>
          )}
          {storeInfo?.contactInfo ? (
            <div className="mb-40 md:col-span-1 col-span-2">
              <div
                className="text-theme text-14 mb-22 md:mb-16 tracking-14 md:tracking-16 leading-16 md:leading-19 uppercase font-bold"
                font-customisation="section-heading"
                color-customisation="footer-heading"
              >
                CONTACT
              </div>
              {storeInfo?.contactInfo?.number ? (
                <p
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  <a className="flex" href={`tel:${storeInfo?.contactInfo?.number}`} id="footer-contact-number">
                    {storeInfo?.contactInfo?.number}
                  </a>
                </p>
              ) : null}
              {storeInfo?.contactInfo?.email ? (
                <p
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  <a
                    id="footer-contact-mail"
                    className="flex"
                    href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                      storeInfo?.contactInfo?.email
                    }`}
                  >
                    <p className="md:w-full break-all">{storeInfo?.contactInfo?.email}</p>
                  </a>
                </p>
              ) : null}
              {storeInfo?.contactInfo?.address ? (
                <p
                  className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22"
                  font-customisation="para-text"
                  color-customisation="footer-links"
                >
                  <div className="flex" id="footer-contact-address">
                    <p className="md:w-190 break-word-only">{storeInfo?.contactInfo?.address}</p>
                  </div>
                </p>
              ) : null}
            </div>
          ) : null}
          <div className="mb-40 md:col-start-4 md:row-start-1 row-start-2 row-end-3">
            <div
              className="text-theme text-14 mb-22 md:mb-16 tracking-14 md:tracking-16 leading-16 md:leading-19 uppercase font-bold"
              font-customisation="section-heading"
              color-customisation="footer-heading"
            >
              MY ACCOUNT
            </div>
            {features?.features?.buyerLogin && (
              <>
                {!getBuyerJWTToken() ? (
                  <Link to={RouteMap.ACCOUNT_LOGIN}>
                    <p
                      className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22 cursor-pointer"
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-log-in"
                    >
                      Login
                    </p>
                  </Link>
                ) : (
                  <Link to={RouteMap.ACCOUNT_PROFILE}>
                    <p
                      className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22 cursor-pointer"
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-profile"
                    >
                      Profile
                    </p>
                  </Link>
                )}
              </>
            )}
            <Link to={RouteMap.TRACK_ORDER}>
              <p
                className="flex text-footerText md:text-theme text-14 font-medium leading-16 mb-22 cursor-pointer"
                font-customisation="para-text"
                color-customisation="footer-links"
                id="footer-track-order"
              >
                Track Order
              </p>
            </Link>
          </div>
        </div>
        {storeInfo?.hasSocialLinks ? (
          <div className="md:float-right mt-20 md:mt-0">
            <div
              className="tracking-14 md:tracking-16 text-14 md:hidden leading-16 font-bold mb-28"
              font-customisation="para-text"
              color-customisation="footer-heading"
            >
              FOLLOW US
            </div>
            <div className="flex items-center md:justify-between md:mr-100 md:mb-34">
              <div
                className="tracking-14 md:tracking-16 text-14 md:text-16 hidden md:block leading-19 font-bold mr-34"
                font-customisation="para-text"
                color-customisation="footer-heading"
              >
                FOLLOW US
              </div>
              {storeInfo?.social?.facebook ? (
                <a href={storeInfo?.social?.facebook} target="_blank" rel="noreferrer noopener" title="Facebook">
                  <Image
                    noDefaultDimensions={true}
                    src={FacebookFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-facebook-link"
                  />
                </a>
              ) : null}
              {storeInfo?.social?.twitter ? (
                <a href={storeInfo?.social?.twitter} target="_blank" rel="noreferrer noopener" title="Twitter">
                  <Image
                    noDefaultDimensions={true}
                    src={TwitterFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-twitter-link"
                  />
                </a>
              ) : null}
              {storeInfo?.social?.instagram ? (
                <a href={storeInfo?.social?.instagram} target="_blank" rel="noreferrer noopener" title="Instagram">
                  <Image
                    noDefaultDimensions={true}
                    src={InstagramFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-instagram-link"
                  />
                </a>
              ) : null}
              {storeInfo?.social?.pinterest ? (
                <a href={storeInfo?.social?.pinterest} target="_blank" rel="noreferrer noopener" title="Pinterest">
                  <Image
                    noDefaultDimensions={true}
                    src={PinterestFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-pinterest-link"
                  />
                </a>
              ) : null}
              {storeInfo?.social?.youtube ? (
                <a href={storeInfo?.social?.youtube} target="_blank" rel="noreferrer noopener" title="YouTube">
                  <Image
                    noDefaultDimensions={true}
                    src={YoutubeFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-youtube-link"
                  />
                </a>
              ) : null}
              {storeInfo?.social?.linkedin ? (
                <a href={storeInfo?.social?.linkedin} target="_blank" rel="noreferrer noopener" title="LinkedIn">
                  <Image
                    noDefaultDimensions={true}
                    src={LinkedinFooter}
                    className="w-20 h-18 mr-20"
                    id="footer-linkedin-link"
                  />
                </a>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {!features?.features?.whiteLabel ? (
        <div style={{ background: '#292869' }} className="p-6 flex justify-center">
          <Image noDefaultDimensions={true} src={Logo} className="w-250 my-20 md:my-24 h-30" />
        </div>
      ) : null}
    </div>
  );
};

export default connect(
  ({ features, pageHeader }: RootState) => ({
    features,
    pageHeader,
  }),
  {},
)(Footer);
