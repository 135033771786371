// import { ReactComponent as ToastCloseIcon } from '@common/icons/ToastClose.svg';
import { RouteMap } from '@common/RouteMap';
import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
import blackClose from '@images/whiteClose.svg';
import Link from '@lib/Link';
import React from 'react';

import { WISHLIST_TOAST } from '../../../../common/store/wishList/slice';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <div>
      {quickView ? (
        <div>
          {toasts.map((toast) => (
            <div
              key={toast.key}
              className={
                toast.variant === 'bg-toast-error'
                  ? 'bg-errorToast text-white shadow-md'
                  : 'bg-successToast text-white shadow-md'
              }
            >
              <div className="p-12 w-full flex font-medium items-center py-16 text-14 md:text-16 justify-between">
                <div
                  className="w-70p"
                  font-customisation="para-text"
                  dangerouslySetInnerHTML={{ __html: toast.content }}
                ></div>
                <div className="font-bold cursor-pointer  text-white" onClick={() => removeToast(toast.key)}>
                  <img
                    src={blackClose}
                    alt="Close"
                    className="mx-16 cursor-pointer h-10 md:h-14"
                    onClick={() => removeToast(toast.key)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {toasts.map((toast) => (
            <div key={toast.key} className="cointainer mx-auto px-16 md:px-140 ">
              <div
                className="p-12 md:p-24 rounded border-border border w-full flex font-medium items-center py-16 text-14 md:text-16 justify-between"
                font-customisation="para-text"
              >
                <div className="w-3/5" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                {toast?.options?.find((option) => option?.name == WISHLIST_TOAST) ? null : (
                  <Link to={RouteMap.CART}>
                    <div
                      className="font-bold cursor-pointer text-12 md:text-16 tracking-16 py-12 md:py-16 px-18 md:px-24 bg-black rounded text-white"
                      onClick={() => removeToast(toast.key)}
                      button-customisation="page-button"
                    >
                      VIEW CART
                    </div>
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Toasts;
